import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';

interface ComponentTab {
  label: string;
  route: string;
}

@Component({
  selector: 'app-component-playground',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    RouterModule, MatListModule, MatGridListModule
  ],
  templateUrl: './component-playground.component.html',
  styleUrls: ['./component-playground.component.scss']
})
export class ComponentPlaygroundComponent {

  components: ComponentTab[] = [
    { label: 'Colors', route: './colors' },
    { label: 'Typography', route: './typography' },
    { label: 'Spacing', route: './spacing' },
    { label: 'Icons', route: './icons' },
    { label: 'Tables', route: './tables' },
    { label: 'Dashboard', route: './dashboard' },
    { label: 'Tooltip', route: './tooltip' },
    { label: 'Button', route: './button' },
    { label: 'Chips', route: './chips' },
    { label: 'Field', route: './field' },
    { label: 'Upload File', route: './upload-file' },
    { label: 'Menu', route: './menu' },
    { label: 'Logo', route: './logo' },
    { label: 'Tabs', route: './tabs' },
    { label: 'Dialog', route: './dialog' },
    { label: 'Toast', route: './toast' },
    { label: 'Status', route: './status' },
    { label: 'Selection Controls', route: './selection-controls' },
    { label: 'Date Picker', route: './date-picker' },
    { label: 'Time Picker', route: './time-picker' },
    { label: 'Avatar', route: './avatar' },
    { label: 'Badges', route: './badges' },
    { label: 'Divider', route: './divider' },
    { label: 'Card List', route: './card-list' },
    { label: 'Accordion', route: './accordion' },
    { label: 'Slider', route: './slider' },
    { label: 'Steps', route: './steps' },
    { label: 'Drawer', route: './drawer' },
    { label: 'Search', route: './search' },
    { label: 'Charts', route: './charts' },
    { label: 'Empty State', route: './empty-state' },
    { label: 'Carousel', route: './carousel' },
    { label: 'Pipes', route: './pipes' },
    { label: 'Directives', route: './directives' },
    { label: 'Alerts', route: './alerts' },
  ];
}
