import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { HttpService } from "@core/services";
import { SafeAny } from "@core/types";
import { DialogCloseDirective, DialogComponent, DialogContentDirective, DialogFooterDirective, DialogOpenDirective, DialogTitleDirective } from "@shared/components";

@Component({
  selector: 'app-custom-content',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    DialogComponent,
    DialogOpenDirective,
    DialogCloseDirective,
    DialogContentDirective,
    DialogFooterDirective,
    DialogTitleDirective
  ],
  template: `
    <div *ngIf="data">
      <p>{{data.message}}</p>
    </div>
    <div *ngIf="!data">Loading...</div>
    <button mat-raised-button color="primary" [appDialogOpen]="dialog">Open Another Dialog</button>

    <app-dialog #dialog width="200px">
      <ng-container *appDialogTitle>Bank Details</ng-container>
      <app-custom-content *appDialogContent></app-custom-content>
      <ng-container *appDialogFooter>
        <button mat-stroked-button color="primary" class="w-full" [appDialogClose]="dialog">Close</button>
      </ng-container>
    </app-dialog>
  `
})
export class CustomContentComponent implements OnInit {
  dialog = inject(DialogComponent);
  data: SafeAny;
  constructor(private http: HttpService) { }

  ngOnInit() {
    this.http.get('https://api.example.com/data').subscribe(
      (response) => {
        this.data = response;
      },
      (error) => {
        console.error('Error fetching data', error);
      }
    );
  }
}



@Component({
  selector: 'app-cp-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    CustomContentComponent,
    DialogComponent,
    DialogOpenDirective,
    DialogCloseDirective,
    DialogContentDirective,
    DialogFooterDirective,
    DialogTitleDirective
  ],
  template: `
    <button mat-raised-button color="primary" [appDialogOpen]="dialog">Open Dialog</button>
    <app-dialog #dialog width="460px">
      <ng-container *appDialogTitle>Bank Details</ng-container>
      <app-custom-content *appDialogContent></app-custom-content>
      <ng-container *appDialogFooter>
        <button mat-stroked-button color="primary" class="w-full" [appDialogClose]="dialog">Close</button>
      </ng-container>
    </app-dialog>
    <ng-template #closeIconRef>
      close
    </ng-template>
    <ng-template #titleRef>
      title
    </ng-template>
  `
})
export class CpDialogComponent {
}

