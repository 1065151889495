import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DateRangePickerComponent, MatErrorMessagesComponent, MatRangeInputComponent } from '@shared/components';
import { CopyInputDirective, FormResetDirective } from '@shared/directives';
import { CommonValidators } from 'app/core/validators/common.validators';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-cp-field',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatErrorMessagesComponent,
    FormResetDirective,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DateRangePickerComponent,
    CopyInputDirective,
    ClipboardModule,
    MatRangeInputComponent,
    JsonPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <p>
    <mat-form-field class="app-small-input app-input-rounded">
      <mat-label>Rounded and Small Input</mat-label>
      <button matPrefix mat-icon-button aria-label="Clear" class="btn-small">
            <span class="fa-regular fa-magnifying-glass text-secondary-60"></span>
      </button>  
      <input matInput required #input> 
      @if (input.value) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="input.value=''" class="btn-small">
            <span class="fa-solid fa-xmark text-secondary-60"></span>
        </button>
      }
      <mat-hint>Help Text</mat-hint>
    </mat-form-field>
  </p>
  <form [formGroup]="form">
    <mat-form-field  >
      <mat-label>Precision</mat-label>
        <input
        matInput 
        type="number"
        formControlName="precision"
        required
        />
      <mat-error appMatErrorMessages></mat-error>
      <mat-hint>Hint</mat-hint>
    </mat-form-field>
      <app-mat-range-input 
        formGroupName="deposit" 
        checkboxLabel="Deposit Enable"
        [precision]="form.get('precision')!.value"
      ></app-mat-range-input>
      <app-mat-range-input 
        formGroupName="withdraw" 
        checkboxLabel="Withdraw Enable"
        [precision]="form.get('precision')!.value"
      ></app-mat-range-input>
    <button mat-flat-button color="warn" [appFormReset]="form">Reset</button>

  </form>
  <h4>Form Value</h4>
  {{form.value | json}}
  {{form.valid}}
  <p>
    <mat-form-field>
      <mat-label>Default</mat-label>
      <input matInput [owlDateTime]="dt1" #datePicker [owlDateTimeTrigger]="dt1" placeholder="Date Time" required >
      <owl-date-time #dt1></owl-date-time>
      <mat-hint>Help Text</mat-hint>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Default</mat-label>
      <input matInput placeholder="Enter you value" required appCopyInput>   
      <button mat-icon-button type="button" matSuffix>
            <span class="fa-regular fa-copy text-secondary-60 fa-2xs"></span>
        </button>  
      <mat-hint>Help Text</mat-hint>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Empty Hovered</mat-label>
      <input matInput required>
      <mat-hint>Help Text</mat-hint>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Disabled</mat-label>
      <input matInput placeholder="Enter you value" required disabled>
      <mat-hint>Hint</mat-hint>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Disabled Populated</mat-label>
      <input matInput placeholder="Enter you value" required disabled value="Text">
      <mat-hint>Hint</mat-hint>
    </mat-form-field>
  </p>
  <form [formGroup]="form">
  <mat-form-field  >
      <mat-label>Enter your username</mat-label>
        <input
        matInput
        formControlName="username"
        required
        />
      <mat-error appMatErrorMessages></mat-error>
      <mat-hint>Hint</mat-hint>
    </mat-form-field>
    <mat-form-field  >
      <mat-label>Enter your email</mat-label>
        <input
        matInput
        placeholder="pat@example.com"
        formControlName="email"
        required
        />
      <mat-error appMatErrorMessages></mat-error>
      <mat-hint>Hint</mat-hint>
    </mat-form-field>
    <button mat-flat-button color="primary" type="submit" >Submit</button>
    <button mat-flat-button color="warn" [appFormReset]="form">Reset</button>
  </form>
  <form [formGroup]="form">
    <app-date-range-picker [formGroup]="form" fromControlName="fromDateTime" toControlName="toDateTime" class="h-fit-content col-span-3"></app-date-range-picker>
  </form>
  `,
  styles: ``
})
export class CpFieldComponent {
  form: FormGroup;
  fb = inject(FormBuilder)
  constructor() {
    this.form = new FormGroup({
      username: new FormControl('', [CommonValidators.required, CommonValidators.minLength(5)]),
      email: new FormControl('', [CommonValidators.required, CommonValidators.email]),
      fromDateTime: new FormControl(''),
      toDateTime: new FormControl(''),
      precision: new FormControl(2),
      deposit: new FormGroup({
        enable: new FormControl<boolean>(true),
        min: new FormControl<number | null>(0, [CommonValidators.required, CommonValidators.min(0), CommonValidators.max(60000)]),
        max: new FormControl<number | null>(10, [CommonValidators.required, CommonValidators.min(0), CommonValidators.max(65000)])
      }, { validators: CommonValidators.minMaxValidator() }),
      withdraw: new FormGroup({
        enable: new FormControl<boolean>(true),
        min: new FormControl<number | null>(0, [CommonValidators.required, CommonValidators.min(0), CommonValidators.max(60000)]),
        max: new FormControl<number | null>(10, [CommonValidators.required, CommonValidators.min(0), CommonValidators.max(65000)])
      }, { validators: CommonValidators.minMaxValidator() })
    });
  }
}
