import { Routes } from "@angular/router";

export const COMPONENT_PLAYGROUND_ROUTES: Routes = [
    {
        path: '',
        loadComponent: () => import('@dev-tools').then(c => c.ComponentPlaygroundComponent),
        children: [
            { path: '', redirectTo: 'colors', pathMatch: 'full' },
            {
                path: 'colors',
                loadComponent: () => import('@dev-tools').then(c => c.CpColorsComponent)
            },
            {
                path: 'typography',
                loadComponent: () => import('@dev-tools').then(c => c.CpTypographyComponent)
            },
            {
                path: 'spacing',
                loadComponent: () => import('@dev-tools').then(c => c.CpSpacingComponent)
            },
            {
                path: 'icons',
                loadComponent: () => import('@dev-tools').then(c => c.CpIconsComponent)
            },
            {
                path: 'tables',
                loadComponent: () => import('@dev-tools').then(c => c.CpTablesComponent)
            },
            {
                path: 'dashboard',
                loadComponent: () => import('@dev-tools').then(c => c.CpDashboardComponent)
            },
            {
                path: 'tooltip',
                loadComponent: () => import('@dev-tools').then(c => c.CpTooltipComponent)
            },
            {
                path: 'button',
                loadComponent: () => import('@dev-tools').then(c => c.CpButtonComponent)
            },
            {
                path: 'chips',
                loadComponent: () => import('@dev-tools').then(c => c.CpChipsComponent)
            },
            {
                path: 'field',
                loadComponent: () => import('@dev-tools').then(c => c.CpFieldComponent)
            },
            {
                path: 'upload-file',
                loadComponent: () => import('@dev-tools').then(c => c.CpUploadFileComponent)
            },
            {
                path: 'menu',
                loadComponent: () => import('@dev-tools').then(c => c.CpMenuComponent)
            },
            {
                path: 'logo',
                loadComponent: () => import('@dev-tools').then(c => c.CpLogoComponent)
            },
            {
                path: 'tabs',
                loadComponent: () => import('@dev-tools').then(c => c.CpTabsComponent)
            },
            {
                path: 'dialog',
                loadComponent: () => import('@dev-tools').then(c => c.CpDialogComponent)
            },
            {
                path: 'toast',
                loadComponent: () => import('@dev-tools').then(c => c.CpToastComponent)
            },
            {
                path: 'status',
                loadComponent: () => import('@dev-tools').then(c => c.CpStatusComponent)
            },
            {
                path: 'selection-controls',
                loadComponent: () => import('@dev-tools').then(c => c.CpSelectionControlsComponent)
            },
            {
                path: 'date-picker',
                loadComponent: () => import('@dev-tools').then(c => c.CpDatePickerComponent)
            },
            {
                path: 'time-picker',
                loadComponent: () => import('@dev-tools').then(c => c.CpTimePickerComponent)
            },
            {
                path: 'avatar',
                loadComponent: () => import('@dev-tools').then(c => c.CpAvatarComponent)
            },
            {
                path: 'badges',
                loadComponent: () => import('@dev-tools').then(c => c.CpBadgesComponent)
            },
            {
                path: 'divider',
                loadComponent: () => import('@dev-tools').then(c => c.CpDividerComponent)
            },
            {
                path: 'card-list',
                loadComponent: () => import('@dev-tools').then(c => c.CpCardListComponent)
            },
            {
                path: 'accordion',
                loadComponent: () => import('@dev-tools').then(c => c.CpAccordionComponent)
            },
            {
                path: 'slider',
                loadComponent: () => import('@dev-tools').then(c => c.CpSliderComponent)
            },
            {
                path: 'steps',
                loadComponent: () => import('@dev-tools').then(c => c.CpStepsComponent)
            },
            {
                path: 'drawer',
                loadComponent: () => import('@dev-tools').then(c => c.CpDrawerComponent)
            },
            {
                path: 'search',
                loadComponent: () => import('@dev-tools').then(c => c.CpSearchComponent)
            },
            {
                path: 'charts',
                loadComponent: () => import('@dev-tools').then(c => c.CpChartsComponent)
            },
            {
                path: 'empty-state',
                loadComponent: () => import('@dev-tools').then(c => c.CpEmptyStateComponent)
            },
            {
                path: 'carousel',
                loadComponent: () => import('@dev-tools').then(c => c.CpCarouselComponent)
            },
            {
                path: 'pipes',
                loadComponent: () => import('@dev-tools').then(c => c.CpPipesComponent)
            },
            {
                path: 'directives',
                loadComponent: () => import('@dev-tools').then(c => c.CpDirectivesComponent)
            },
            {
                path: 'alerts',
                loadComponent: () => import('@dev-tools').then(c => c.CpAlertComponent)
            },
        ]
    },
];

